import { Link } from "react-router-dom";

export default function NftCard({ nft }) {

    return (
        <Link to={`/nft/${nft.id}`}>
            <div className="card nft p-4">
                <div className="row">
                    <div className="col-3">
                        <div className="thumb-info-wrapper">
                            <img
                                src={nft.image}
                                alt={`NFT ${nft.id}`}
                                className="img-responsive"
                                style={{ maxHeight: '250px' }}
                            />
                        </div>
                    </div>
                    <div className="col-9">
                        <h4 className="entry-title">{nft?.name}</h4>
                        <h5 className='nft-title'>{nft?.description?.length > 50 ? (
                            <strong>{nft?.description?.substring(0, 50)}...</strong>
                        ) : (
                            <strong>{nft?.description}</strong>
                        )}</h5>
                    </div>
                </div>
            </div>
        </Link>
    )
}
