import React from 'react'
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import NftList from '../../Components/Home/NftList';

export default function Home() {
    return (
        <div className='container vh-80'>
            <div className="row py-2">
                <div className="col-md-12">
                    <Breadcrumb>
                        <Breadcrumb.Item href="#">Início</Breadcrumb.Item>
                        <Breadcrumb.Item active>Documentos Oficiais</Breadcrumb.Item>
                    </Breadcrumb>
                </div>
            </div>
            <div className="row mt-3">
                <h2 className='my-3 ps-page-title'>Documentos Oficiais</h2>
                <p>Documentos oficiais do Partido, do Secretário-geral e do Governo socialista, que orientam as nossas políticas.</p>
            </div>
            <NftList />
        </div>
    )
}
