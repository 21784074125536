import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { Link } from 'react-router-dom';
import { FaFacebookF, FaTwitter, FaYoutube, FaInstagram, FaFlickr } from 'react-icons/fa'
import NavDropdown from 'react-bootstrap/NavDropdown';

export default function Header() {
    return (
        <div>
            <div className="container-fluid header-top">
                <div className="row justify-content-end">
                    <div className="col-md-4">
                        <Nav className="">
                            <Nav.Link href="#home"><FaFacebookF className='nav-icon' /></Nav.Link>
                            <Nav.Link href="#link"><FaTwitter className='nav-icon' /></Nav.Link>
                            <Nav.Link href="#link"><FaYoutube className='nav-icon' /></Nav.Link>
                            <Nav.Link href="#link"><FaInstagram className='nav-icon' /></Nav.Link>
                            <Nav.Link href="#link"><FaFlickr className='nav-icon' /></Nav.Link>
                        </Nav>
                    </div>
                </div>
            </div>
            <Navbar bg="white" expand="lg" className='container-fluid border-bottom'>
                <div className="container">
                    <div className="row w-100 py-2 align-items-center">
                        <div className="col-md-6">
                            <Navbar.Brand href="/" className='ms-5'>
                                <Link to="/"> <img src='/images/logo.svg' alt='logo' className='logo' /></Link>
                            </Navbar.Brand>
                        </div>
                        <div className="col-md-6 d-flex justify-content-center">
                            <div>
                                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                                <Navbar.Collapse id="basic-navbar-nav">
                                    <Nav className="">
                                        <NavDropdown title="QUEM SOMOS" id="basic-nav-dropdown">
                                            <NavDropdown.Item href="#action/3.2">A nossa história</NavDropdown.Item>
                                            <NavDropdown.Item href="#action/3.3">ORGÃOS DO PS</NavDropdown.Item>
                                            <NavDropdown.Item href="#action/3.4">Mulheres Socialistas – Igualdade e Direitos</NavDropdown.Item>
                                        </NavDropdown>
                                        <NavDropdown title="A NOSSA POLÍTICA" id="basic-nav-dropdown">
                                            <NavDropdown.Item href="#action/3.1">Áreas temáticas</NavDropdown.Item>
                                            <NavDropdown.Item href="#action/3.2">Documentos Oficiais</NavDropdown.Item>
                                            <NavDropdown.Item href="#action/3.3">ELEIÇÕES</NavDropdown.Item>
                                            <NavDropdown.Item href="#action/3.4">PROGRAMAS ELEITORAIS</NavDropdown.Item>
                                        </NavDropdown>
                                        <NavDropdown title="INFORMAÇÃO" id="basic-nav-dropdown">
                                            <NavDropdown.Item href="#action/3.1">INICIATIVAS</NavDropdown.Item>
                                            <NavDropdown.Item href="#action/3.2">Notícias</NavDropdown.Item>
                                            <NavDropdown.Item href="#action/3.3">Edições Ação Socialista Digital</NavDropdown.Item>
                                            <NavDropdown.Item href="#action/3.4">Estudos e estatísticas</NavDropdown.Item>
                                        </NavDropdown>
                                        <NavDropdown title="Seja PS" id="basic-nav-dropdown">
                                            <NavDropdown.Item href="#action/3.1">Militante</NavDropdown.Item>
                                            <NavDropdown.Item href="#action/3.2">Simpatizante</NavDropdown.Item>
                                        </NavDropdown>
                                        <Nav.Link href="#link">ÁREA DO MILITANTE</Nav.Link>
                                    </Nav>
                                </Navbar.Collapse>
                            </div>
                        </div>
                    </div>
                </div>
            </Navbar>
        </div>
    )
}
