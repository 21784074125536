import React from 'react'

export default function Footer() {
    return (
        <div className='' id='footer'>
            <div className="container-fluid bg-light">

                <div className="ps-footer-wrapper">
                    <h3 class="ps-footer-label">PARTICIPE NO PARTIDO SOCIALISTA</h3>
                    <div className="row py-3 w-100 pb-0">
                        <div className="col-md-8 footer-left ps-5">
                            <h4 className='mt-5'>SEJA PS</h4>
                            <p>Se tem interesse em ajudar-nos a melhorar este país, assuma o compromisso político como militante do Partido Socialista.</p>
                            <a href="/" className='text-danger'>JUNTE-SE AGORA</a>
                            <div className="newsletter my-3">
                                <h4>SUBSCREVA A NOSSA NEWSLETTER</h4>
                                <div className="row">
                                    <div className="col-md-8">
                                        <form class="row row-cols-lg-auto g-2 align-items-center">
                                            <div class="col-12">
                                                <div class="input-group">
                                                    <input type="email" class="form-control" id="email" placeholder="example@example.com" />
                                                </div>
                                            </div>
                                            <div class="col-12">
                                                <button type="submit" class="btn btn-secondary">Subscrever</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 footer-right">
                            <h4 className='mt-3'>LIGAÇÕES ÚTEIS</h4>
                            <ul>
                                <li>Grupo Parlamentar do Partido Socialista</li>
                                <li>Juventude Socialista</li>
                                <li>Partido Socialista Europeu</li>
                                <li>Aliança Progressista de Socialistas e Democratas</li>
                                <li>Internacional Socialista</li>
                                <li>Orgãos do PS</li>
                                <li>Federações</li>
                                <li>Política de privacidade</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="row footer-bottom">
                    <div className="col-md-12">
                        <p className='mt-1'>© Copyright 2023 - Partido Socialista. Todos os direitos reservados</p>
                    </div>
                </div>
            </div>
        </div>
    )
}
