import { BrowserRouter, Routes, Route, } from "react-router-dom";
import Home from "./Pages/Home/Home";
import NftDetails from "./Pages/NFT/NftDetails";
import { Worker } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import './App.css';
import Header from "./Components/Layout/Header";
import Footer from "./Components/Layout/Footer";
import GoToTop from "./Components/common/GoToTop";

function App() {

  return (
    <BrowserRouter>
      <div className="App">
        <Header />
        <div id="main" style={{ backgroundImage: `url('/images/article-pattern-bg-logo.png')` }}>
          <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.6.172/build/pdf.worker.min.js">
            <Routes>
              <Route path="/" exact element={<Home />} />
              <Route path="/nft/:id" exact element={<NftDetails />} />
            </Routes>
          </Worker>
        </div>
        <Footer />
        <GoToTop />
      </div>
    </BrowserRouter>
  );
}

export default App;
